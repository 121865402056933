<template>
  <v-container
    fluid
    fill-height
    class="ma-0 pa-0 install-extension"
    style="background: #f4f3f3"
  >
    <v-layout column fill-height class="pa-0 ma-0 mt-6 ml-7 mr-6">
      <div class="install-title ms-2 mb-3" v-text="$t('install.title')" />
      <v-alert
        color="#DFE7F6"
        transition="scroll-y-transition"
        class="d-flex align-center rounded-lg pa-6 mb-7"
      >
        <v-layout row flex-nowrap>
          <v-layout column>
            <v-icon
              color="#2C58A0"
              size="18"
              class="me-2 ph-fill"
              style="line-height: 22.4px"
            >
              $info
            </v-icon>
          </v-layout>
          <v-layout column>
            <v-layout
              row
              class="install-info mb-1"
              v-html="$t('install.info')"
            />
            <v-layout row>
              <v-btn
                text
                height="22"
                elevation="0"
                class="pa-0"
                id="install-invite-btn"
                @click="inviteDialog = true"
              >
                <div class="text-none install-info-non-admin">
                  {{ $t("install.isNotAdmin") }}
                </div>
              </v-btn>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-alert>
      <v-card outlined class="py-7 pl-8 pr-4 mb-6" style="padding-right: 36px">
        <div class="d-flex">
          <div class="pe-3">
            <div
              style="height: 36px"
              class="install-step-number align-content-center"
            >
              <v-scale-transition leave-absolute>
                <v-icon v-if="step > 1" size="28" color="#42a574">
                  $success
                </v-icon>
              </v-scale-transition>
              <v-scale-transition leave-absolute>
                <v-avatar v-if="step === 1" size="22" color="#3498DB">
                  1
                </v-avatar>
              </v-scale-transition>
            </div>
          </div>
          <div class="mb-10 flex-grow-1">
            <div class="mb-3 d-flex justify-space-between align-center">
              <div class="install-step-title">
                {{ $t("install.app.title") }}
              </div>
              <v-btn
                text
                height="36"
                color="#3498DB"
                class="px-3"
                @click="redirectToDocumentation"
              >
                <v-icon size="24" color="#3498DB" class="me-3">
                  ph-map-trifold
                </v-icon>
                <div
                  class="text-none text-decoration-underline install-button-doc"
                >
                  {{ $t("install.documentation") }}
                </div>
              </v-btn>
            </div>

            <div>
              <div>
                <div
                  class="install-content-workspace mb-6"
                  v-html="
                    $t('install.app.description', {
                      provider: $t('install.workspace'),
                    })
                  "
                />
              </div>
              <!-- TODO: Instalação para Microsoft -->
              <!-- <div >
                <div class="install-content-workspace mb-6" v-html="$t('install.app.description', {provider: $t('install.microsoft')})" />
              </div> -->
              <div>
                <v-btn
                  height="38"
                  elevation="0"
                  color="#3498DB"
                  @click="installApp"
                  class="mb-1"
                >
                  <div class="text-none install-button-action">
                    {{ $t("install.app.action") }}
                  </div>
                </v-btn>
              </div>
              <v-scroll-y-transition leave-absolute>
                <div v-if="installStatus === 'installing'" class="mt-4">
                  <v-sheet outlined rounded="lg" class="pa-6 mr-6">
                    <div class="d-flex">
                      <div class="me-4 d-flex align-center">
                        <v-progress-circular
                          size="36"
                          color="#3498DB"
                          indeterminate
                          class="mr-1"
                        >
                        </v-progress-circular>
                      </div>
                      <div
                        class="install-new-tab-text"
                        v-html="$t('install.app.newTab')"
                      />
                      <div>
                        <v-icon size="16" style="top: -22px; right: -10px">
                          ph-x
                        </v-icon>
                      </div>
                    </div>
                  </v-sheet>
                </div>
              </v-scroll-y-transition>
              <v-scroll-y-transition leave-absolute>
                <v-layout row v-if="installStatus === 'installed'" class="my-3">
                  <span class="install-content-success">
                    {{ $t("install.app.success") }}
                  </span>
                  <v-icon right size="24" color="#42a574" class="ms-2">
                    $success
                  </v-icon>
                </v-layout>
              </v-scroll-y-transition>
            </div>
          </div>
        </div>

        <v-layout row flex-nowrap>
          <v-layout column shrink class="pe-3">
            <v-layout
              row
              shrink
              align-center
              style="height: 24px"
              class="install-step-number"
            >
              <v-scale-transition leave-absolute>
                <v-icon v-if="step > 2" size="28" color="#42a574">
                  $success
                </v-icon>
              </v-scale-transition>
              <v-scale-transition leave-absolute>
                <v-avatar v-if="step === 2" size="22" color="#3498DB">
                  2
                </v-avatar>
              </v-scale-transition>
              <v-scale-transition leave-absolute>
                <v-avatar
                  v-if="step === 1"
                  size="22"
                  color="white"
                  class="install-step-number-inactive"
                >
                  2
                </v-avatar>
              </v-scale-transition>
            </v-layout>
            <v-layout row grow></v-layout>
          </v-layout>
          <v-layout column grow>
            <v-layout row justify-space-between align-center>
              <div class="install-step-title">
                {{ $t("install.sync.title") }}
              </div>
            </v-layout>

            <v-layout row class="mb-1">
              <!-- Acompanhamento de sincronização -->
              <v-scroll-y-transition leave-absolute>
                <v-sheet
                  v-if="step == 2"
                  outlined
                  rounded="lg"
                  class="mt-6 py-4 px-5"
                  width="100%"
                >
                  <v-layout row flex-nowrap align-center>
                    <v-layout column shrink class="me-4">
                      <v-avatar color="#2C58A01F" size="68">
                        <v-icon
                          color="#2C58A0"
                          size="32"
                          dark
                          class="ph-fill"
                          v-text="hourglassIcon"
                        />
                      </v-avatar>
                    </v-layout>
                    <v-layout column grow align-start>
                      <v-layout
                        row
                        class="install-new-tab-text mb-4"
                        v-html="$t('install.sync.info')"
                      />
                      <v-layout row style="width: 100%">
                        <v-progress-linear
                          color="#2C58A0"
                          background-color="#E7E7E7"
                          :value="progress"
                          :height="22"
                          rounded
                          :indeterminate="
                            syncUsersNotInProgress ||
                            firstSyncUsersStatus.total_users_created ==
                              firstSyncUsersStatus.total_users
                          "
                        >
                          <template #default>
                            <span
                              v-if="!syncUsersNotInProgress"
                              class="install-progress-text"
                            >
                              {{ firstSyncUsersStatus.total_users_created }}/{{
                                firstSyncUsersStatus.total_users
                              }}
                            </span>
                          </template>
                        </v-progress-linear>
                      </v-layout>
                    </v-layout>
                  </v-layout>
                </v-sheet>
              </v-scroll-y-transition>

              <v-scroll-y-transition leave-absolute>
                <v-layout
                  row
                  v-if="step > 1 && firstSyncUsersStatus.status === 'finished'"
                  class="mt-3"
                >
                  <span class="install-content-success">
                    {{ $t("install.sync.success") }}
                  </span>
                  <v-icon right size="24" color="#42a574" class="ms-2">
                    $success
                  </v-icon>
                </v-layout>
              </v-scroll-y-transition>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-card>

      <!-- TODO: Guia rápido -->
      <!-- <v-layout row justify-end>
        <v-btn dark height="38" rounded color="#8165D1">
          <v-icon left color="white">ph-shooting-star</v-icon>
          <div class="text-none">{{ $t("install.guide") }}</div>
          <v-icon right color="white">ph-arrows-out-simple</v-icon>
        </v-btn>
      </v-layout> -->
    </v-layout>

    <v-dialog
      v-model="inviteDialog"
      max-width="866"
      content-class="install-extension rounded-lg"
    >
      <v-sheet flat color="#F6F5F5" class="install-invite-header">
        <v-layout row justify-space-between align-center style="height: 62px">
          <div></div>
          <div class="install-invite-title">
            {{ $t("install.invite.title") }}
          </div>
          <v-btn text @click="inviteDialog = false">
            <v-icon size="18" color="#545454">$close</v-icon>
          </v-btn>
        </v-layout>
      </v-sheet>

      <v-sheet color="white" class="py-6 px-10">
        <v-layout row class="mb-7">
          <v-layout column class="me-10">
            <div class="install-invite-field text-capitalize mb-3">
              {{ $t("common.to") }}
            </div>
            <div>
              <v-text-field
                v-model="email"
                outlined
                :rules="emailRule"
                :placeholder="$t('install.invite.email')"
                class="install-invite-input"
              />
            </div>
          </v-layout>
          <v-layout column class="ms-1">
            <div class="subject text-capitalize mb-3">
              {{ $t("common.subject") }}
            </div>
            <div id="subject-text-field">
              <v-text-field
                outlined
                readonly
                disabled
                id="subject-input"
                placeholder="Convite para a Conecta Suite"
                class="install-invite-input"
                background-color="#F0F0F0"
              />
            </div>
          </v-layout>
        </v-layout>
        <v-layout row>
          <v-layout column>
            <div class="install-invite-field text-capitalize mb-3">
              {{ $t("common.email") }}
            </div>
            <div>
              <v-textarea
                v-model="body"
                outlined
                :placeholder="$t('install.invite.body')"
                class="install-invite-input"
                no-resize
              />
            </div>
          </v-layout>
        </v-layout>
        <v-layout row justify-space-between align-center>
          <div>
            <v-btn
              text
              height="38"
              color="#4D4D4D"
              class="px-3"
              @click="copyInvitation"
            >
              <v-icon size="24" color="#4D4D4D" class="me-2"> ph-link </v-icon>
              <div class="text-none install-invite-copy">
                {{ $t("install.invite.copy") }}
              </div>
            </v-btn>
          </div>
          <div>
            <v-btn
              outlined
              height="38"
              color="#3498DB"
              class="px-3 me-5"
              @click="inviteDialog = false"
              :disabled="sendInviteLoading"
            >
              <div class="text-none text-capitalize c-font-poppins">
                {{ $t("action.cancel") }}
              </div>
            </v-btn>

            <v-btn
              :loading="sendInviteLoading"
              :disabled="sendInviteLoading || !validEmail"
              elevation="0"
              height="38"
              color="#3498DB"
              class="px-3"
              @click="sendInvitation"
            >
              <div class="text-none white--text text-capitalize c-font-poppins">
                {{ $t("action.send") }}
              </div>
            </v-btn>
          </div>
        </v-layout>
      </v-sheet>
    </v-dialog>
  </v-container>
</template>

<script>
import { isEmptyObject, openInNewTab, rules } from "@/helpers/services/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";

const API_CALL_LIMIT = 3;

export default {
  name: "Install",

  data: () => ({
    step: 1,
    installStatus: "idling",
    inviteDialog: false,
    email: "",
    body: "",
    usersSynced: 50,
    usersLength: 200,
    syncStatus: "idling",
    hourglassAnimation: null,
    hourglassIcon: "ph-hourglass-high",
    intervalId: null,
    installationCheckAmount: 0,
    sendInviteLoading: false,
  }),

  watch: {
    installStatus() {
      if (this.installStatus === "installed") {
        this.step = 2;
        this.firstSyncUsers();
      } else {
        this.step = 1;
      }
    },

    syncStatus() {
      if (this.syncStatus === "syncing") {
        this.hourglassAnimation = setInterval(this.animateHourglass, 1000);
      } else if (this.syncStatus === "synced") {
        this.step = 3;
        clearInterval(this.hourglassAnimation);
      } else {
        this.step = 1;
      }
    },

    firstSyncUsersStatus() {
      if (this.firstSyncUsersStatus.status == "finished") {
        this.step = 3;
        clearInterval(this.hourglassAnimation);
      }
    },
  },

  computed: {
    ...mapGetters(["company", "token", "firstSyncUsersStatus"]),

    emailRule() {
      return [
        (v) => !!v || this.$t("textFieldRules.requiredEmail"),
        rules.validEmail,
      ];
    },

    validEmail() {
      return !!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
        this.email
      );
    },

    syncUsersNotInProgress() {
      return isEmptyObject(this.firstSyncUsersStatus);
    },

    progress() {
      const { total_users, total_users_created } = this.firstSyncUsersStatus;

      return (total_users_created / total_users) * 100;
    },
  },

  methods: {
    ...mapActions(["getSyncUsersStatus", "getContractedPlans"]),

    ...mapMutations(["setCompany", "setCurrentUser", "setSnackBar"]),

    installApp() {
      if (this.intervalId || this.installStatus === "installed") return;

      this.redirectToMarketplace();
      this.intervalId = setInterval(this.checkAppInstalled, 20000);

      this.installStatus =
        this.installStatus === "idling"
          ? "installing"
          : this.installStatus === "installing"
          ? "installed"
          : this.installStatus === "installed"
          ? "idling"
          : "installed";
    },

    copyInvitation() {
      navigator.clipboard
        .writeText(window.location.origin)
        .then(() => {
          this.setSnackBar({
            message: this.$t("install.success.linkCopied"),
            show: true,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },

    sendInvitation() {
      this.sendInviteLoading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/integrations/install-invite`;

      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      const payload = {
        email: this.email,
        message: this.body,
      };

      this.$axios
        .post(url, payload, auth)
        .then(() => {
          this.setSnackBar({
            message: this.$t("install.success.invitationSent"),
            show: true,
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.sendInviteLoading = false;
          this.inviteDialog = false;
        });
    },

    animateHourglass() {
      if (this.hourglassIcon === "ph-hourglass-high") {
        this.hourglassIcon = "ph-hourglass-medium";
      } else if (this.hourglassIcon === "ph-hourglass-medium") {
        this.hourglassIcon = "ph-hourglass-low";
      } else {
        this.hourglassIcon = "ph-hourglass-high";
      }
    },

    firstSyncUsers() {
      this.syncStatus = "syncing";
    },

    checkAppInstalled() {
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/installed`;

      const auth = {
        headers: {
          Authorization: this.token,
        },
      };

      this.$axios
        .get(url, auth)
        .then(async (response) => {
          this.setCompany(response.data.company);
          this.setCurrentUser(response.data.user);

          this.installStatus = "installed";
          this.stopPolling();
          this.getSyncUsersStatus(response.data.company);

          await this.getContractedPlans();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.installationCheckAmount++;

          if (this.installationCheckAmount == API_CALL_LIMIT) {
            this.stopPolling();
          }
        });
    },

    stopPolling() {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.installationCheckAmount = 0;

      if (this.installStatus != "installed") {
        this.installStatus = "idling";
      }
    },

    redirectToMarketplace() {
      const link = `https://workspace.google.com/marketplace/app/conecta_suite/${process.env.VUE_APP_MARKETPLACE_APP_ID}`;
      openInNewTab(link);
    },

    redirectToDocumentation() {
      const link = `https://conectasuite.com/faq/instalar-conecta-suite/`;
      openInNewTab(link);
    },
  },

  beforeMount() {
    this.getSyncUsersStatus();
  },
};
</script>

<style lang="scss" scoped>
.c-font-poppins {
  font-family: Poppins !important;
}

.c-font-montserrat {
  font-family: Montserrat !important;
}

.c-font-14-400 {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22.4px !important;
}

.c-font-14-400-21 {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.c-font-14-500 {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
}

.c-font-14-600 {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 22.4px !important;
}

.c-text-underline {
  text-decoration-line: underline !important;
  text-decoration-style: solid !important;
}

.c-text-common {
  text-align: left;
  text-underline-position: from-font !important;
  text-decoration-skip-ink: none !important;
}

.install-title {
  @extend .c-font-montserrat;
  @extend .c-text-common;
  font-size: 22px;
  font-weight: 500;
  line-height: 39.6px;
  color: #000000;
}

.install-extension .row {
  margin: 0px;
}

.install-extension .layout {
  min-width: initial;
}

.install-step-number-inactive {
  background: white !important;
  color: #9a9a9a !important;
  border: 1px solid #9a9a9a !important;
}

.install-info {
  @extend .c-font-poppins;
  @extend .c-font-14-400;
  @extend .c-text-common;
  color: #2c58a0;
}

.install-info-non-admin {
  @extend .c-font-poppins;
  @extend .c-font-14-400;
  @extend .c-text-underline;
  @extend .c-text-common;
  color: #2c58a0;
}

.install-step-number {
  @extend .c-font-poppins;
  @extend .c-text-common;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #ffffff;
}

.install-step-title {
  @extend .c-font-poppins;
  @extend .c-text-common;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #747474;
}

.install-button-doc {
  @extend .c-font-poppins;
  @extend .c-text-underline;
  @extend .c-text-common;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #3498db;
}

.install-content-workspace {
  @extend .c-font-poppins;
  @extend .c-font-14-400-21;
  @extend .c-text-common;
  color: #747474;
}

.install-content-success {
  @extend .c-font-poppins;
  @extend .c-text-common;
  @extend .c-font-14-500;
  color: #42a574;
}

.install-button-action {
  @extend .c-font-poppins;
  @extend .c-text-common;
  @extend .c-font-14-500;
  color: white;
}

.install-new-tab-text {
  @extend .c-font-poppins;
  @extend .c-font-14-400;
  @extend .c-text-common;
  color: #747474;
}

.install-progress-text {
  @extend .c-font-poppins;
  @extend .c-font-14-600;
  @extend .c-text-common;
  color: #2c58a0;
}

.install-invite-header {
  border-bottom: 1px solid #d7d7d7 !important;
}

.install-invite-title {
  @extend .c-font-poppins;
  @extend .c-text-common;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.3339366316795349px;
  color: #2b2c2d;
}

.install-invite-field {
  @extend .c-font-poppins;
  @extend .c-font-14-500;
  @extend .c-text-common;
  color: black;
}

.install-invite-copy {
  @extend .c-font-poppins;
  @extend .c-font-14-500;
  @extend .c-text-common;
  letter-spacing: 0.3339366316795349px;
  color: #4d4d4d;
}

.install-invite-input {
  @extend .c-font-poppins;
  @extend .c-font-14-400-21;
  @extend .c-text-common;
  color: #909090;
}

.subject {
  @extend .c-text-common;
  @extend .c-font-14-500;
  color: #909090;
  font-family: "Poppins", sans-serif;
}
</style>

<style>
.install-extension .v-text-field--outlined fieldset {
  border: 1px solid #adadad !important;
  border-radius: 9px;
}
.install-extension .v-text-field--outlined.v-input--is-focused fieldset {
  border: 2px solid #adadad !important;
}

#subject-text-field
  .install-invite-input
  .v-input__control
  .v-input__slot
  fieldset {
  border: 1px solid #d5d5d5 !important;
}
</style>
